import ApiErrorResponses from '@/core/api/errors/ApiErrorResponses';
import {
  ApiErrorResponseContract,
  ApiErrorResponseMessagesContract,
} from '@/core/api/errors/contracts/ApiErrorResponseContract';
import AuthSettings from '@/core/api/settings/AuthSettings';

export default class ApiError extends ApiErrorResponses {
  /**
   * Error symbol
   */
  private readonly symbol!: string | null;

  /**
   * Error messages list
   */
  private readonly messages!: ApiErrorResponseMessagesContract[] | null;

  /**
   * Constructor
   * @param error
   * @param catchExpired
   */
  constructor(error: ApiErrorResponseContract | ApiError, catchExpired: boolean = true) {
    if (error instanceof ApiError) {
      super(error.status);

      this.symbol = error.symbol;
      this.messages = error.messages;
    } else {
      super(error.status);

      this.symbol = error.symbol;
      this.messages = error.messages;
    }

    if (catchExpired) {
      this.catchExpiredAuth();
    }
  }

  /**
   * Get messages
   */
  public getMessages(): ApiErrorResponseMessagesContract[] {
    return this.messages ? this.messages : [];
  }

  /**
   * Check if has messages
   */
  public hasMessages(): boolean {
    return !!this.messages && this.messages.length > 0;
  }

  /**
   * Get error symbol
   */
  public getSymbol(): string {
    return this.symbol ? this.symbol : '';
  }

  /**
   * Check if has symbol
   */
  public hasSymbol(): boolean {
    return !!this.symbol;
  }

  /**
   * Catch expired token
   */
  private catchExpiredAuth() {
    if (!this.isUnauthorized()) {
      return;
    }

    AuthSettings.setExpired();
  }
}
