// core modules
import StoreSettingsStorageModel from '@/core/settings/models/StoreSettingsStorageModel';

// app modules

const modules: any[] = [
  // core modules
  StoreSettingsStorageModel,

  // app modules
];

export { modules };
