




import { Component, Vue } from 'vue-property-decorator';

import DefaultLayout from '@/app/layouts/default/DefaultLayout.vue';

@Component({
  components: {
    DefaultLayout,
  },
})
export default class App extends Vue {
}
