const appRoutes = [
  // {
  //   path: '/',
  //   name: 'index',
  //   component: () => import(/* webpackChunkName: "dashboard" */ '@/app/views/DashboardView.vue'),
  //   meta: {
  //     title: 'dashboard',
  //   },
  // },
  {
    path: '/lista-gosci-vhTexkSbdp62ULJWSeJKLmMsQWWdVJ4z',
    name: 'guests-lists',
    component: () =>
      import(/* webpackChunkName: "guests-list" */ '@/app/views/modules/guests-lists/GuestsListView.vue'),
    meta: {
      title: 'guests-lists.main',
    },
  },

  {
    path: '/redirect',
    name: 'redirect',
    component: () => import(/* webpackChunkName: "redirect" */ '@/shared/resources/views/RedirectView.vue'),
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "not-found" */ '@/app/views/NotFoundView.vue'),
    meta: {
      title: 'not-found',
    },
  },
];

export { appRoutes };
