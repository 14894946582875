import VueConfigServiceProvider from '@/app/providers/VueConfigServiceProvider';
import AppServiceProvider from '@/app/providers/AppServiceProvider';
import SettingsServiceProvider from '@/app/providers/SettingsServiceProvider';
import LocaleServiceProvider from '@/app/providers/LocaleServiceProvider';

const providers = [
  new VueConfigServiceProvider(),
  new LocaleServiceProvider(),
  new SettingsServiceProvider(),
  new AppServiceProvider(),
];

export { providers };
