import SettingsStorage from '../../../core/settings/SettingsStorage';
import SettingsStorageRegistry from '@/core/settings/SettingsStorageRegistry';
import BrowserLocalSettingsStorageAdapter from '@/core/settings/adapters/BrowserLocalSettingsStorageAdapter';

export default class UISettings {
  /**
   * Set storage
   */
  public static readonly storage: SettingsStorage = SettingsStorageRegistry.register(
    new BrowserLocalSettingsStorageAdapter('UI_'),
  );

  /**
   * Cache value at name in object
   * @param name
   * @param value
   * @param objectName
   */
  private static cacheInObject(name: string, value: any, objectName: string) {
    const object: any = this.storage.getObject(objectName) || {};
    object[name] = value;

    this.storage.setObject(objectName, object);
  }

  /**
   * Get from cached object
   * @param name
   * @param objectName
   */
  private static getFromCachedObject(name: string, objectName: string) {
    const object: any = this.storage.getObject(objectName);
    return object ? object[name] : null;
  }
}
