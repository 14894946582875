import VueI18n from 'vue-i18n';
import Swal from 'sweetalert2';

import StorageService from '@/shared/lib/services/storageService';

const PIN_LENGTH = 4;
const SUCCESS_TIMER = 1500;

export default class Popup {
  /**
   * Load needed data/services
   * @param i18n
   */
  public static load(i18n: VueI18n) {
    this.i18n = i18n;
  }

  /**
   * Display pin popup
   * @returns {*}
   */
  public static async pin(onConfirm: (pin: string) => void, onCancel?: () => void, force: boolean = false) {
    // get pin saved in storage
    const savedPin: string = StorageService.get('user_pin');

    if (savedPin && !force) {
      return onConfirm(savedPin);
    } else {
      const result = await Swal.fire(this._prepareOptions({
        title: this.i18n.t('popups.pin.title'),
        input: 'text',
        inputAttributes: {
          id: 'pin' + new Date().getTime(),
          name: 'pin' + new Date().getTime(),
          maxlength: 4,
          autocomplete: 'none',
        },
        inputValidator: (value: string) => {
          if (!value) {
            return this.i18n.t('popups.pin.validation.required');
          }

          if (value.length < PIN_LENGTH) {
            return this.i18n.t('popups.pin.validation.length');
          }
        },
        showCancelButton: true,
        customClass: 'popup popup__pin',
      }));

      this.close();

      // if dismiss
      if (result.dismiss) {
        return onCancel ? onCancel() : null;
      }

      // get pin from popup result
      const pin = result.value as string;

      // save pin TODO
      // if (userCan(permissions.app.pinStored) && !force) {
      //   StorageService.set('user_pin', pin);
      // }

      return onConfirm(pin);
    }
  }

  /**
   * Display success popup
   * @param html
   * @param timer
   * @param title
   */
  public static success(html = '', timer = SUCCESS_TIMER, title = this.i18n.t('popups.success.title')) {
    this.show({
      icon: 'success',
      title,
      html,
      timer,
    });
  }

  /**
   * Display warning popup
   * @param html
   * @param timer
   * @param title
   */
  public static warning(html = '', timer = null, title = this.i18n.t('popups.warning.title')) {
    this.show({
      icon: 'warning',
      title,
      html,
      timer,
    });
  }

  /**
   * Display forbidden popup
   * @param html
   * @param retryCallback
   */
  public static async forbiddenWarning(html: string = '', retryCallback?: () => void) {
    const result = await this.show({
      icon: 'warning',
      title: this.i18n.t('popups.warning.title'),
      html,
      timer: null,
      showCancelButton: true,
      confirmButtonText: this.i18n.t('popups.buttons.retry'),
    });

    if (result.value && !!retryCallback) {
      return retryCallback();
    }
  }

  /**
   * Display error popup
   * @param html
   * @param timer
   * @param title
   */
  public static error(
    html = this.i18n.t('popups.error.description'),
    timer = null,
    title = this.i18n.t('popups.error.title'),
  ) {
    this.show({
      icon: 'error',
      title,
      html,
      timer,
    });
  }

  /**
   * Display confirm popup
   * @param html
   * @param icon
   * @param title
   * @returns {Promise<*>}
   */
  public static confirm(
    html = this.i18n.t('popups.confirm.description'),
    icon = 'warning',
    title = this.i18n.t('popups.confirm.title'),
  ) {
    return this.show({
      icon,
      title,
      html,
      showCancelButton: true,
      confirmButtonText: this.i18n.t('common.yes'),
    }).then((result: any) => {
      return new Promise((resolve, reject) => {
        if (result.value) {
          resolve();
        } else {
          reject(new Error());
        }
      });
    });
  }

  /**
   * Display popup
   * @param options
   */
  public static async show(options: any) {
    return Swal.fire(this._prepareOptions(options));
  }

  /**
   * Close popup
   */
  public static close() {
    Swal.close();
  }

  private static i18n: VueI18n;

  /**
   * Prepare options
   * @param options
   * @private
   */
  private static _prepareOptions(options: any) { // TODO contract
    return {
      heightAuto: false,
      buttonsStyling: false,
      confirmButtonText: this.i18n.t('popups.buttons.confirm'),
      cancelButtonText: this.i18n.t('popups.buttons.cancel'),
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn btn-outline-secondary',
      customClass: 'popup',
      ...options,
    };
  }
}
