import Vue from 'vue';
import Router, { RawLocation, Route } from 'vue-router';

import { appRoutes } from '@/app/routes/appRoutes';
import Locale from '@/core/locale/Locale';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: appRoutes,
});

router.beforeEach(async (to: Route, from: Route, next: (to?: RawLocation) => void) => {
  next();
});

router.afterEach((to: Route) => {
  const appTitle: string = Locale.i18n.t('app.title').toString();

  if (to && to.meta && to.meta.title) {
    const title = Locale.i18n.t('app.views.' + to.meta.title);
    document.title = `${title} - ${appTitle}`;
  } else {
    document.title = appTitle;
  }
});

export { router };
