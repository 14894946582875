import ServiceProvider from '@/core/providers/ServiceProvider';
import Locale from '@/core/locale/Locale';
import { languages } from '@/app/config/languagesConfig';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import GlobalSettings from '@/shared/lib/settings/GlobalSettings';

export default class LocaleServiceProvider extends ServiceProvider {
  public async init() {
    Vue.use(VueI18n);

    Locale.init(new VueI18n({
      locale: 'pl',
      fallbackLocale: 'en',
      // formatFallbackMessages: true,
    }));

    // set available languages
    Locale.languages(languages);

    // register locales paths
    await Locale.registerLocales('@/app/locales/{lang}/index');
    await Locale.switch(this.determineUserLang());

    // save active lang in settings
    GlobalSettings.lang = Locale.symbol;
  }

  /**
   * Try to determine user language
   */
  private determineUserLang(): string {
    const settingsLanguage = GlobalSettings.lang;
    if (settingsLanguage) {
      return settingsLanguage;
    }
    const browserLanguage = navigator.language.toLowerCase();
    if (browserLanguage) {
      return browserLanguage;
    }
    // Default language
    return process.env.VUE_APP_LANG_DEFAULT || 'en';
  }
}
