



































import {Component, Vue} from 'vue-property-decorator';

import GridContainer from '@/shared/resources/components/grid/GridContainer.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import Divider from '@/shared/resources/components/Divider.vue';

@Component({
  components: {
    Divider,
    GridCol,
    GridRow,
    GridContainer,
  },
})
export default class DefaultLayout extends Vue {
}
