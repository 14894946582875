import ServiceProvider from '@/core/providers/ServiceProvider';
import ApiSchemaV2 from '@/shared/lib/api/schema/ApiSchemaV2';
import ApiSettings from '@/core/api/settings/ApiSettings';

export default class SettingsServiceProvider extends ServiceProvider {
  /**
   * Init service provider
   */
  public async init() {
    ApiSettings.apiURL = process.env.VUE_APP_API_URL as string;
    ApiSettings.schema = new ApiSchemaV2();
    ApiSettings.schema.apiSettings = ApiSettings;
    ApiSettings.loadCachedData();
  }
}
