import {AxiosError, AxiosResponse} from 'axios';
import Pagination from '@/core/support/pagination/Pagination';
import ApiORMRelationsQueryBuilder from '@/core/bridge/orm/api/relations/ApiORMRelationsQueryBuilder';
import ApiORMQueryRelationHandler from '@/core/bridge/orm/api/relations/ApiORMQueryRelationHandler';
import IndexableInterface from '@/core/interfaces/IndexableInterface';
import {ApiORMRelationsSchemaContract} from '@/core/bridge/orm/api/relations/contracts/ApiORMRelationsResponseContract';
import {removeStringDuplicates} from '@/core/helpers/utils/ArrayUtils';
import ApiSchema from '@/core/api/schema/ApiSchema';
import {ApiErrorResponseContract} from '@/core/api/errors/contracts/ApiErrorResponseContract';
import ApiErrorSchemaEnum from '@/core/api/support/ApiErrorSchemaEnum';
import Model from '@vuex-orm/core/dist/src/model/Model';

export default class ApiSchemaV2 extends ApiSchema {
  /**
   * Private fields
   */
  private idFactory?: (item: any) => string;
  private objectModifier?: (item: any) => any;

  /**
   * Constructor
   * @param idFactory
   * @param objectModifier
   */
  constructor(idFactory?: (item: any) => string, objectModifier?: (item: any) => any) {
    super();
    this.idFactory = idFactory;
    this.objectModifier = objectModifier;
  }

  /**
   * Parse response
   * @param response
   * @param requestData
   */
  public parseResponse(response: AxiosResponse, requestData?: IndexableInterface): object {
    if (response.data && !!response.data.data && !!response.data.meta) {
      return {
        ...response,
        data: response.data.data,
        pagination: {
          page: response.data.meta.current_page,
          size: Number(response.data.meta.per_page),
          total: response.data.meta.total,
        },
      };
    } else {
      let data: any = [];

      if (!response) {
        data = [];
      } else if (response.data === undefined) {
        data = response;
      } else if (!response.data.data) {
        data = response.data;
      } else if (typeof response.data.data === 'string') {
        data = JSON.parse(response.data.data);
      } else {
        data = response.data.data;
      }

      return {
        ...response,
        data,
      };
    }
  }

  /**
   * Prepare relation data
   * @param relations
   */
  public prepareRelationQueryParams(relations: ApiORMRelationsQueryBuilder): object {
    const parsed: object = this.recurrencyRelationDataMapper(relations.getRelations());
    return { relations: btoa(JSON.stringify(parsed)) };
  }

  /**
   * Parse pagination
   * @param responsePagination
   * @param modelPagination
   */
  public parsePagination(responsePagination: any, modelPagination: Pagination): Pagination {
    if (!responsePagination) {
      return new Pagination(null, null, modelPagination.getSort({ field: 'id', desc: 'desc' }), null);
    }
    return new Pagination(
      responsePagination.page,
      responsePagination.size === 2147483647 ? -1 : responsePagination.size,
      modelPagination.getSort({ field: 'id', desc: 'desc' }),
      responsePagination.total,
    );
  }

  /**
   * Parse error response
   * @param error
   */
  public parseErrorResponse(error: AxiosError): ApiErrorResponseContract {
    const result: ApiErrorResponseContract = {
      status: error && error.response ? Number(error.response.status) : null,
      symbol: null,
      messages: [],
    };

    if (!error || !error.response || !error.response.data) {
      return result;
    }

    if (error.response.data.code && typeof error.response.data.code === 'string') {
      result.symbol = error.response.data.code;
    }

    if (error.response.data.validationErrors) {
      const messages: string | string[] = error.response.data.validationErrors;
      if (typeof messages === 'string') {
        result.messages.push({
          type: ApiErrorSchemaEnum.MESSAGE,
          message: messages,
        });
      } else if (Array.isArray(messages)) {
        Object.values(messages).forEach((value: any) => {
          result.messages.push({
            type: ApiErrorSchemaEnum.SYMBOL,
            message: value.code + '_' + value.path,
          });
        });
      }
    } else if (error.response.data.params) {
      Object.keys(error.response.data.params).map((key: string | number) => {
        const param: { code: string } = error.response
          ? JSON.parse(error.response.data.params[key])
          : null;

        if (param) {
          result.messages.push({
            type: ApiErrorSchemaEnum.SYMBOL,
            message: param.code,
          });
        }
      });
    } else if (error.response.data.message) {
      result.messages.push({
        type: ApiErrorSchemaEnum.MESSAGE,
        message: error.response.data.message,
      });
    }
    return result;
  }

  /**
   * Prepare query pagination
   * @param pagination
   */
  public prepareQueryPagination(pagination: Pagination): object {
    const request = pagination.getRequestData();

    if (request.limit) {
      request.size = request.limit;
      delete request.limit;
    }

    return request;
  }

  /**
   * Get relations map
   */
  public getRelationsMap(): ApiORMRelationsSchemaContract {
    return {
      // TODO
    };
  }

  /**
   * Get relation model
   * @param relationName
   */
  public getRelationModel(relationName: string): typeof Model {
    return this.getRelationsMap()[relationName];
  }

  /**
   * Recurrency relation data mapper
   * @param relations
   */
  private recurrencyRelationDataMapper(relations: ApiORMQueryRelationHandler[]): IndexableInterface[] {
    const result: IndexableInterface[] = [];

    relations.forEach((relation: ApiORMQueryRelationHandler) => {
      const entity: IndexableInterface = {};
      entity.key = relation.name;

      if (relation.relations) {
        entity.relations = this.recurrencyRelationDataMapper(relation.relations);
      }

      if (relation.fields) {
        const relationModel: any | undefined = this.getRelationModel(relation.name); // TODO change to Model
        let fields = relation.fields;

        if (relationModel) {
          // add primary key
          if (Array.isArray(relationModel.primaryKey)) {
            fields = [
              ...relationModel.primaryKey,
              ...relationModel.relationRequiredFields,
              ...fields,
            ];
          } else {
            fields = [
              relationModel.primaryKey,
              ...relationModel.relationRequiredFields,
              ...fields,
            ];
          }
        }

        entity.fields = removeStringDuplicates(fields);
      }

      result.push(entity);
    });

    return result;
  }
}
