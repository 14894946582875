import VueI18n, { LocaleMessages } from 'vue-i18n';
import { isArray } from 'lodash';
import LangSchema from '@/core/locale/LangSchema';
import { LangSchemasContract } from '@/core/locale/contracts/LangSchemasContract';
import EN from '@/core/locale/schema/EN';
import ApiHeaders from '@/core/api/headers/ApiHeaders';
import ApiHeadersGroupEnum from '@/core/api/headers/support/ApiHeadersGroupEnum';

export default class Locale {
  /**
   * Default locale symbol
   */
  public static default: string = 'en';

  /**
   * Access to i18n module
   */
  public static i18n: VueI18n;

  /**
   * Active language schema
   */
  public static schema: LangSchema;

  /**
   * Initialize VueI18n module
   * @param i18n
   */
  public static init(i18n: VueI18n) {
    this.i18n = i18n;
  }

  /**
   * Return locale symbol
   */
  public static get symbol() {
    return this.schema.symbol;
  }

  /**
   * Return locale symbol
   */
  public static get region() {
    return this.schema.region;
  }

  /**
   * Set available languages languages
   * @param languages
   */
  public static languages(languages: LangSchemasContract) {
    this.availableSchemas = languages;
  }

  /**
   * Switch language by code
   * @param code
   */
  public static async switch(code: string) {
    // load schema system
    this.loadActiveLangSchema(this.recognizeSymbol(code));

    this.i18n.locale = this.schema.symbol;

    if (this.loaded.indexOf(this.schema.symbol) === -1) {
      // first time loading locales of this language
      await this.loadLocales(this.dictionaries);

      // register language loading
      this.loaded.push(this.schema.symbol);
    }

    // inject active lang code to html
    this.injectActiveLangToHtml();

    this.initialized = true;

    return code;
  }

  /**
   * Return available languages 2-letters symbol
   */
  public static get availableLanguages(): object[] {
    return Object.values(this.availableSchemas).map((lang: LangSchema) => {
      return {
        symbol: lang.symbol,
        region: lang.region,
        name: lang.name,
      };
    });
  }

  /**
   * Return available currencies symbol
   */
  public static get availableCurrencies(): object[] {
    return Object.values(this.availableSchemas).map((lang: LangSchema) => {
      return {
        currency: lang.currency,
      };
    });
  }

  /**
   * Convert price to local format
   * @param amount
   * @param digits
   */
  public static price(amount: number, digits = 2): string {
    return this.formatPrice(amount, digits, {
      minimumFractionDigits: digits,
    });
  }

  /**
   * Get formatted amount with currency symbol
   * @param amount
   * @param currency
   * @param digits
   */
  public static priceWithCurrency(amount: number, currency: string | null = null, digits = 2): string {
    return this.formatPrice(amount, digits, {
      style: 'currency',
      currency: currency || this.schema.currency,
    });
  }

  /**
   * Get currency
   */
  public static get currency(): string {
    return this.schema.currency;
  }

  /**
   * Get currency symbol
   */
  public static get currencySymbol(): string {
    return (0).toLocaleString(
      this.symbol,
      {
        style: 'currency',
        currency: this.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    ).replace(/\d/g, '').trim();
  }

  /**
   * Get date formatted to locale string
   * @param date
   */
  public static date(date: Date, options?: Intl.DateTimeFormatOptions) {
    return date.toLocaleDateString(this.schema.symbol, options);
  }

  /**
   * Get time formatted to locale string
   * @param date
   */
  public static time(date: Date, options?: Intl.DateTimeFormatOptions) {
    return date.toLocaleTimeString(this.schema.symbol, options);
  }

  /**
   * Get time formatted to locale string
   * @param date
   */
  public static datetime(date: Date, options?: Intl.DateTimeFormatOptions) {
    return date.toLocaleString(this.schema.symbol, options);
  }

  /**
   * Convert special chars
   * @param s
   */
  public static latinisation(s: string): string {
    const parsed: string = s.toLocaleLowerCase(this.schema.symbol);

    Object.keys(this.schema.specialChars).forEach((value: string, index: number) => {
      parsed.replace(value, this.schema.specialChars[index]);
    });
    return parsed;
  }

  /**
   * Return single phaze variety
   * @param value
   * @param singularNominativ
   * @param pluralNominativ
   * @param pluralGenitive
   */
  public static variety(
    value: number | null,
    singularNominativ: string,
    pluralNominativ: string,
    pluralGenitive: string,
  ): string {
    return this.schema.variety(value, singularNominativ, pluralNominativ, pluralGenitive);
  }

  /**
   * Register locales object
   * @param path
   */
  public static async registerLocales(path: string | string[]) {
    if (isArray(path)) {
      this.dictionaries = {
        ...this.dictionaries,
        ...path,
      };
    } else {
      this.dictionaries.push(path);
    }

    if (this.initialized) {
      await this.loadLocales(path);
    }
  }

  /**
   * Add local messages manually
   * @param messages
   */
  public static setLocalMessages(messages: LocaleMessages) {
    this.i18n.setLocaleMessage(this.schema.symbol, messages);
  }

  /**
   * Language init before flag
   */
  private static initialized: boolean = false;

  /**
   * Loaded language list
   */
  private static loaded: string[] = [];

  /**
   * Registered dictionaries list
   */
  private static dictionaries: string[] = [];

  /**
   * Available language languages
   */
  private static availableSchemas: LangSchemasContract = {};

  /**
   * Load active language schema system
   */
  private static loadActiveLangSchema(symbol?: string) {
    if (!symbol || !this.availableSchemas[symbol]) {
      // switch to default code when lang not available
      this.schema = new EN();
    } else {
      this.schema = this.availableSchemas[symbol];
    }
  }

  /**
   * Load locales from path
   * @param path
   */
  private static async loadLocales(path: string | string[]) {
    if (isArray(path)) {
      path.forEach((value: string) => this.loadLocales(value));
    } else {
      const parsed: string = this.getLocalesPath(path);
      import(/* webpackChunkName: "lang-[request]" */ `@/${parsed}`).then((locales) => {
        this.setLocalMessages(this.merge(locales));
      });
    }
  }

  /**
   * Get merged messages from dictionary
   * @param locales
   */
  private static merge(locales: any): LocaleMessages {
    const messages: LocaleMessages = {};

    Object.keys(locales.default).forEach((key: any) => {
      if (!messages[key]) {
        messages[key] = locales.default[key];
      } else {
        messages[key] = { ...messages[key], ...locales.default[key] };
      }
    });

    return messages;
  }

  /**
   * Get parsed locales path
   * @param path
   */
  private static getLocalesPath(path: string): string {
    return path.replace('@/', '')
      .replace('{lang}', this.schema.symbol.toLowerCase())
      .replace('{LANG}', this.schema.symbol.toUpperCase());
  }

  /**
   * Inject language symbol to DOM
   */
  private static injectActiveLangToHtml() {
    // update DOM
    const html = document.querySelector('html');
    if (html) {
      html.setAttribute('schema', this.schema.symbol);
    }
    // change header in requests
    ApiHeaders.set([
      ApiHeadersGroupEnum.DATA,
      ApiHeadersGroupEnum.FILES,
    ], 'Accept-Language', this.schema.symbol);
  }

  /**
   * Recognize and save symbol
   * @param code
   */
  private static recognizeSymbol(code: string): string {
    if (code.length === 2) {
      return code;
    } else if (code.length === 5) {
      return code.substr(0, 2);
    }
    return this.default;
  }

  /**
   * Format price
   * @param amount
   * @param digits
   * @param options
   */
  private static formatPrice(amount: number, digits: number = 2, options?: Intl.NumberFormatOptions) {
    return Number(amount.toFixed(digits)).toLocaleString(this.schema.region, options);
  }
}
